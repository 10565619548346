var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.newTemplate
    ? _c("div", { ref: "newTemplate", staticClass: "newTemplateMain" }, [
        _c("div", { staticClass: "title" }, [
          _c("span", { staticClass: "titleIcon" }),
          _c("span", { staticClass: "titleContent" }, [
            _vm._v(_vm._s(_vm.titleheader)),
          ]),
          _c("img", {
            staticClass: "closeIcon",
            attrs: { src: require("./close.png"), alt: "" },
            on: { click: _vm.tabNewTemplate },
          }),
        ]),
        _c(
          "div",
          { staticClass: "templateNameContent" },
          [
            _vm._m(0),
            _c("el-input", {
              attrs: {
                type: "text",
                placeholder: "请输入内容",
                maxlength: "20",
                "show-word-limit": "",
              },
              model: {
                value: _vm.templateName,
                callback: function ($$v) {
                  _vm.templateName = typeof $$v === "string" ? $$v.trim() : $$v
                },
                expression: "templateName",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "templateNameContent" },
          [
            _vm._m(1),
            _c(
              "div",
              { staticClass: "describe" },
              [
                _c("span", { staticClass: "describeTitle" }, [
                  _vm._v("插入参数："),
                ]),
                _vm._l(_vm.smsIndicator, function (value, index) {
                  return _c(
                    "span",
                    {
                      key: index,
                      staticClass: "pattms",
                      on: {
                        click: function ($event) {
                          return _vm.insertVal(value.source)
                        },
                      },
                    },
                    [
                      _vm._v("$" + _vm._s(value.targetDesc) + " "),
                      _c("span", { staticClass: "stick" }),
                    ]
                  )
                }),
              ],
              2
            ),
            _c("el-input", {
              ref: "input",
              attrs: {
                type: "textarea",
                maxlength: 600 + _vm.count[1],
                "show-word-limit": "",
                placeholder:
                  "支持插入参数，参数按手机号匹配，请保持参数完整性请勿更改",
                autosize: { minRows: 6, maxRows: 10 },
                id: "textarea",
              },
              on: { blur: _vm.campaignNameBlur },
              nativeOn: {
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "delete", [8, 46], $event.key, [
                      "Backspace",
                      "Delete",
                      "Del",
                    ])
                  ) {
                    return null
                  }
                  return _vm.del.apply(null, arguments)
                },
                keyup: function ($event) {
                  return _vm.keyUp()
                },
                click: function ($event) {
                  return _vm.keyUp()
                },
              },
              model: {
                value: _vm.describe,
                callback: function ($$v) {
                  _vm.describe = typeof $$v === "string" ? $$v.trim() : $$v
                },
                expression: "describe",
              },
            }),
            _c("div", { staticClass: "tip" }, [
              _c("span", [
                _vm._v("当前字数："),
                _vm.count[0]
                  ? _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v(_vm._s(_vm.count[0])),
                    ])
                  : _c("span", [_vm._v("0")]),
                _vm._v("/600"),
              ]),
              _c(
                "div",
                { staticStyle: { display: "inline-block" } },
                [
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        placement: "bottom-start",
                        width: "400",
                        trigger: "hover",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "tipNum",
                          attrs: { slot: "reference" },
                          slot: "reference",
                        },
                        [
                          _vm._v(" 计费条数："),
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.count[0] <= 70
                                  ? 1
                                  : Math.ceil(_vm.count[0] / 67)
                              )
                            ),
                          ]),
                        ]
                      ),
                      _vm._t("default", function () {
                        return [
                          _c("div", [
                            _vm._v(
                              "根据工信部统一要求会将短信内容按以下规则拆计费条数："
                            ),
                          ]),
                          _c("div", [
                            _vm._v(
                              "短信字数<=70个字数，按照70个字数计一条计费短信；"
                            ),
                          ]),
                          _c("div", [
                            _vm._v(
                              "短信>70个数，即为长短信，按照67个字数一条计费短信。"
                            ),
                          ]),
                          _c("div", [_vm._v("上限是600字，即最多9条。")]),
                          _c("br"),
                          _c("div", [
                            _c("span", [
                              _vm._v(
                                "由于短信内容可以使用参数，为了确保短信完整性不超上限，所以参数取值计最大数，即带参数的短信计费条数"
                              ),
                            ]),
                            _c(
                              "span",
                              { staticStyle: { "font-weight": "700" } },
                              [_vm._v("仅供参考")]
                            ),
                            _vm._v("。 "),
                          ]),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "submit" }, [
              _c(
                "div",
                { staticClass: "save", on: { click: _vm.saveSubmit } },
                [_vm._v("保存")]
              ),
              _c(
                "div",
                { staticClass: "cancel", on: { click: _vm.tabNewTemplate } },
                [_vm._v("取消")]
              ),
            ]),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "templateMain" }, [
      _c("span", { staticClass: "redTips" }, [_vm._v("*")]),
      _c("span", { staticClass: "templateName" }, [_vm._v("模板名称")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "templateMain" }, [
      _c("span", { staticClass: "redTips" }, [_vm._v("*")]),
      _c("span", { staticClass: "templateName" }, [_vm._v("描述")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }